#wrapper {
  display: flex;

  #content-wrapper {
    background-color: $gray-100;
    width: 100%;
    overflow-x: hidden;

    #content {
      flex: 1 0 auto;
    }
  }
}
.fab {
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  }
  
  .fab:hover {
  opacity: 0.7;
  }
.fa-whatsapp:before {
  content:"\f232"
 }
 .fa-whatsapp-square:before {
  content:"\f40c"
 }
 .whatsapp-button {
  position: fixed;
  bottom: 25px;
  right: 25px;
  height: 55px;
  width: 55px;
  background-color: #25d366;
  z-index: 1000;
}
.text-decoration-none {
  text-decoration: none !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
 .whatsapp-button i {
  color: rgb(255, 255, 255);
  font-size: xx-large;
 }

.border-ligth-dark {
  border-color: $gray-600 !important
}

.cursor-pointer {
  cursor: pointer
}
