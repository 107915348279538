.divider {
    display: flex;
    align-items: center;
    text-align: center;
}

.divider::after,
.divider::before {
    content: "";
    border: 1px solid $silver-chalice;
    flex: 1;
}

.divider:not(:empty)::before {
    margin-right: 2em;
}

.divider:not(:empty)::after {
    margin-left: 2em;
}

.test {
    color: red;
}