.bg-apartments {
    background-image: url('../../../public/img/main3.png');
    background-position: bottom;
    background-size: cover;
    height: 70vh;

    button {
        bottom: 2.5%;
    }
}

.apartmentModalList {
    ul {
        list-style: none;
        padding-left: 4px;
    }

    li {
        border-color: $gray-700;
    }
}

@media only screen and (min-width: 992px) {
    .modal-lg, .modal-xl {
       max-width: 835px !important;
    }
}

@include media-breakpoint-up(sm) {
    .modal-dialog {
        max-width: 565px;
    }
}

@include media-breakpoint-up(lg) {
    .border-appartment {
        border-right: 1px solid $gray-600 !important;
    }
}

