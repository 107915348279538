/* Blog - Timeline
-----------------------------------------------------------------*/

.entry-timeline {
	display: none;
}

@include media-breakpoint-up(lg) {

	.timeline-border {
		position: absolute;
		top: 0;
		left: 50%;
		width: 0;
		border-left: $post-timeline-border-size $post-timeline-border-style $post-timeline-border-color;
		height: 100%;
		transform: translateX(-50%);
	}

	.postcontent .post-timeline::before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		width: 0;
		border-left: 1px dashed $post-timeline-border-color;
		height: 100%;
	}

	.post-timeline .entry-timeline {
		display: block;
		position: absolute;
		border: 2px solid $post-timeline-border-color;
		background-color: $body-bg;
		padding-top: 0;
		text-indent: -9999px;
		top: 40px;
		left: auto;
		right: -6px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		font-size: 1.75rem;
		font-weight: bold;
		color: #AAA;
		text-align: center;
		line-height: 1;
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.post-timeline .entry-timeline .timeline-divider {
		position: absolute;
		top: 4px;
		left: auto;
		right: 15px;
		width: 70px;
		height: 0;
		border-top: 1px dashed $post-timeline-border-color;
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.post-timeline .entry:not(.entry-date-section) {
		padding-right: 85px;
	}

	.post-timeline .entry.alt:not(.entry-date-section) {
		padding-right: inherit;
		padding-left: 85px;
	}

	.post-timeline .alt .entry-timeline {
		right: auto;
		left: -6px;
	}

	.post-timeline .alt .entry-timeline .timeline-divider {
		left: 15px;
		right: auto;
	}

	.post-timeline .entry-timeline span {
		display: block;
		margin-top: 3px;
		font-size: 13px;
		font-weight: normal;
	}

	.post-timeline .entry:hover .entry-timeline,
	.post-timeline .entry:hover .timeline-divider {
		border-color: $primary;
		color: $primary;
	}

}

.entry.entry-date-section { 
	margin: 50px 0 80px;
	text-align: center;
}

.entry.entry-date-section:first-child {
	margin-top: 0;
}

.entry.entry-date-section span {
	display: inline-block;
	width: 250px;
	background-color: $body-bg;
    color: $primary;
	border: 2px solid darken($primary, 6.67);
	font-size: 18px;
	line-height: 50px;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-family: $heading-font;
}

.posts-md .entry-image,
.posts-sm .entry-image {
	margin-bottom: 0;
}

.entry-image,
.entry-image > a,
.entry-image .slide a,
.entry-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}

#content {
	position: relative;
	background-color: $body-bg;
	p { line-height: 1.8; }
	.content-wrap {
		position: relative;
		padding: 80px 0;
		overflow: hidden;
	}
	.container { position: relative; }
}

.grid-container,
.grid-inner {
	position: relative;
	overflow: hidden;
	will-change: transform;
}

.grid-inner {
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

body:not(.device-touch) .grid-container {
	-webkit-transition: height .4s ease;
	-o-transition: height .4s ease;
	transition: height .4s ease;
}